import React, {FC, useEffect} from 'react';
import Layout from '@common/layout';
import Link from 'next/link';
import {GetStaticProps} from 'next';
import {INotFoundPageProps, ITranslation, TLang} from '@common/types';
import {useTranslation} from '@common/hooks/useTranslation';
import {getContacts} from '@redux/contacts/actions';
import {wrapper} from '@redux/index';
import {END} from 'redux-saga';
import {getAppSettings, setApp} from '@redux/app/actions';
import {useRouter} from 'next/router';

const NotFoundPage: FC<INotFoundPageProps> = () => {
  const t = useTranslation('notFoundPage') as ITranslation['notFoundPage']
  const router = useRouter()

  useEffect(() => {
    if (!router.asPath.includes('404')) {
      router.replace(`${router.locale === 'en' ? '/en' : ''}/404`)
    }
  }, [router.asPath])

  return (
    <Layout title="404" pipelineId={undefined}>
      <section className="inner-title">
        <div className="container">
          <h1 className="inner-title__text wrap">
            {t.textGoal}<br/>{t.text404}<br/><br/>{t.textMain} <Link className="simple-link simple-link--no-color"
                                                                     href="/">{t.textLink}</Link>
          </h1>
        </div>
      </section>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = wrapper.getStaticProps(store => async ({...etc}) => {
  const locale = etc.locale as TLang
  store.dispatch(setApp({draftMode: etc.draftMode ?? false}))
  store.dispatch(getAppSettings({locale}))
  store.dispatch(getContacts({locale: locale as TLang}))
  store.dispatch(END)
  await store?.sagaTask.toPromise()

  return {
    props: {
      locale: locale as TLang
    }
  }
})

export default NotFoundPage
